<template>
    <div class="tree-source-panel" @click.stop  :style="{ '--width': width }" @scroll="onScroll">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "TreePanelK",
    props: {
        width: {
            default: "250px",
            type: String,
        },
        // 距离组件底部多远触发加载
        distance: {
            default: 20,
            type: Number
        }
    },
    data() {
        return {
            timer: null,
        }
    },
    methods: {
        onScroll(e) {
            const {scrollTop, clientHeight, scrollHeight } = e.target;
            if (scrollTop !== 0 && scrollHeight - scrollTop - clientHeight  < this.distance + scrollHeight % clientHeight) {
                if (this.timer) {
                    clearInterval(this.timer);
                }
                this.timer = setTimeout(() => {
                    this.$emit('loadMore')
                }, 500);
            }
        }
    }
};
</script>


<style lang="stylus" scoped>
.tree-source-panel
    min-width var(--width, 250px)
    max-height 320px
    overflow-y auto
    background #FFFFFF
    z-index 2
    box-shadow 0 0 10px 0 rgba(0, 0, 0, 0.15)
    position absolute
    left 0
    top 33px
    border: 1px solid black;

</style>
